import styled from "styled-components";

export const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  gap: 2rem;
  h5 {
    color: white;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid white;
    width: 32rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    padding: 2rem;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 100%;
      font-size: 2rem;
    }
  }
`;
