import styled from "styled-components";

export const MainSection = styled.section`
  margin-top: 1.5rem;
  h5 {
    color: white;
  }
  p {
    color: white;
  }
`;
