import React, { useEffect } from "react";
import { IuputWrapper, MainSection } from "./elements";
import useAuthStore from "../../../state/auth-state";
import { useNavigate } from "react-router-dom";

const LogSearch = () => {
  const { user } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate("/");
  }, [user]);

  return (
    <MainSection>
      <IuputWrapper>
        <input type="text" placeholder="Search by UserName" />
        <input type="text" placeholder="Search by Mobileno" />
        <input type="text" placeholder="Search by Cnic" />
      </IuputWrapper>
    </MainSection>
  );
};

export default LogSearch;
