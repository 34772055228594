import React, { useEffect, useState } from "react";
import {
  BodyDiv,
  HeaderDiv,
  IuputWrapper,
  MainBodyDiv,
  MainSection,
  MainWrapper,
} from "./elements";
import useAuthStore from "../../../state/auth-state";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ToastMessage from "../../toastMessage";
import { Spin } from "antd";
import { formatDate } from "../../../state/date-formatter";

const TotalUserLog = () => {
  const [logs, setLogs] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [cnic, setCnic] = useState("");

  const { user, setUser } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      getLogs();
      return;
    }

    const token = localStorage.getItem("admin-token");

    if (!user && !token) navigate("/");

    loginFromToken(token);
  }, [user]);

  const loginFromToken = async (token) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/token-sign-in`,
        {
          token,
        }
      );

      localStorage.removeItem("user-token");
      setUser(res.data);
    } catch (error) {}
  };
  const getLogs = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/logs`
      );

      setLogs(res.data);
    } catch (error) {
      ToastMessage("Error", "Unknown error occured", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let filtered = [];

    if (name !== "") {
      filtered = logs.filter((log) =>
        log.user.toLowerCase().includes(name.toLowerCase())
      );
    } else if (number !== "") {
      filtered = logs.filter((log) => log.number.includes(number));
    } else {
      filtered = logs.filter((log) => log.cnic.includes(cnic));
    }

    setResults(filtered);
  }, [name, number, cnic]);

  const handleNameChange = (e) => {
    setName(e.target.value);
    setCnic("");
    setNumber("");
  };

  const handleNumberChange = (e) => {
    setName("");
    setNumber(e.target.value);
    setCnic("");
  };

  const handleCnicChange = (e) => {
    setName("");
    setNumber("");
    setCnic(e.target.value);
  };

  return user ? (
    <>
      <MainSection>
        <IuputWrapper>
          <input
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder="Search by user name"
          />
          <input
            type="text"
            value={number}
            onChange={handleNumberChange}
            placeholder="Search by mobile no"
          />
          <input
            type="text"
            value={cnic}
            onChange={handleCnicChange}
            placeholder="Search by CNIC"
          />
        </IuputWrapper>
        <MainWrapper>
          <HeaderDiv>
            <h5>Sno.</h5>
            <h5>User Name</h5>
            <h5>Mobile No</h5>
            <h5>CNIC</h5>
            <h5>Date/Time</h5>
          </HeaderDiv>
          <MainBodyDiv className="scrollbar">
            {loading && <Spin />}
            {(name === "" && number === "" && cnic === "" ? logs : results).map(
              (log, index) => (
                <BodyDiv>
                  <h5>{index + 1}</h5>
                  <h5>{log.user}</h5> <h5>{log.number}</h5> <h5>{log.cnic}</h5>{" "}
                  <h5>{formatDate(new Date(log.createdAt))}</h5>
                </BodyDiv>
              )
            )}
          </MainBodyDiv>
        </MainWrapper>
      </MainSection>
    </>
  ) : (
    <div></div>
  );
};

export default TotalUserLog;
