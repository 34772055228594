import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "./element";

import useAuthStore from "../../../state/auth-state";
import axios from "axios";
import { Spin } from "antd";

function HomeComp() {
  const { user, setUser } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) return;

    const token = localStorage.getItem("admin-token");

    if (!user && !token) navigate("/");

    loginFromToken(token);
  }, [user]);

  const loginFromToken = async (token) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/token-sign-in`,
        {
          token,
        }
      );

      setUser(res.data);
      localStorage.removeItem("user-token");
    } catch (error) {}
  };

  return user ? (
    <div>
      <Text>Welcome To Dashboard</Text>
    </div>
  ) : (
    <div></div>
  );
}

export default HomeComp;
