export function formatDate(date) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit", // Use '2-digit' instead of 'pad'
    second: "2-digit",
    hour12: true,
  };
  const time = new Intl.DateTimeFormat("en-US", options).format(date);
  const index = time.indexOf(",");

  return time.substring(index + 2);
}
