import React, { useEffect, useState } from "react";
import {
  BodyDiv,
  HeaderDiv,
  MainBodyDiv,
  MainSection,
  MainWrapper,
} from "./elements";
import axios from "axios";
import ToastMessage from "../../toastMessage";
import { RiDeleteBinLine } from "react-icons/ri";
import useAuthStore from "../../../state/auth-state";
import { useNavigate } from "react-router-dom";

const TotalUser = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { user, setUser } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) return;

    const token = localStorage.getItem("admin-token");

    if (!user && !token) navigate("/");

    loginFromToken(token);
  }, [user]);

  const loginFromToken = async (token) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/token-sign-in`,
        {
          token,
        }
      );

      localStorage.removeItem("user-token");
      setUser(res.data);
    } catch (error) {}
  };

  const getUsers = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/users`
      );

      setUsers(res.data);
    } catch (error) {
      ToastMessage("Error", "Unknown error occured", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleDelete = async (deletedUser) => {
    try {
      ToastMessage(
        "Request submitted",
        "Delete user request submitted",
        "info"
      );

      await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/${deletedUser._id}`
      );

      ToastMessage("Success", "User deleted successfully", "success");

      const filtered = users.filter((user) => user._id !== deletedUser._id);

      setUsers(filtered);
    } catch (error) {
      ToastMessage("Error", "Failed to delete user", "error");
    }
  };

  return user? (
    <MainSection>
      <MainWrapper>
        <HeaderDiv>
          <h5>User Name</h5>
          <h5>Email</h5>
          <h5>Password</h5>
          <h5>Phone no</h5>
          <h5>Delete</h5>
        </HeaderDiv>
        <MainBodyDiv className="scrollbar">
          {users.map((user) => (
            <BodyDiv key={user._id}>
              <h5>{user.username}</h5>
              <h5>{user.email}</h5> <h5>{user.password}</h5>{" "}
              <h5>{user.phoneNumber}</h5>
              <h5 onClick={() => handleDelete(user)}>
                <RiDeleteBinLine color="red" fontSize={20} cursor="pointer" />
              </h5>
            </BodyDiv>
          ))}
        </MainBodyDiv>
      </MainWrapper>
    </MainSection>
  ): <div></div>;
};

export default TotalUser;
