import styled from "styled-components";

export const Text = styled.h5`
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 1rem;
`;

export const Rightdiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

export const MainSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem 1rem 2rem;
  background-color: black;
  border-bottom: 1px solid white;
  @media (max-width: 767px) {
    padding: 1rem 1rem 0.5rem 1rem;
  }

  .title {
    color: #fff;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }
`;
