import React from "react";
import { v2profileicon } from "../../assets";
import { Image } from "react-bootstrap";
import { MainSection, Rightdiv, Text } from "./elements";
import useAuthStore from "../../state/auth-state";
const NavbarComp = () => {
  const { user } = useAuthStore();

  return (
    <>
      <MainSection>
        <h5 className="title">Admin Dashboard</h5>
        <Rightdiv>
          <Text>{user?.name || "user"}</Text>
          <Image src={v2profileicon} width={40} height={40} />
        </Rightdiv>
      </MainSection>
    </>
  );
};

export default NavbarComp;
