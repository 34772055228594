import { useFormik } from "formik";
import React, { useEffect } from "react";
import { MainSection } from "./elements";
import axios from "axios";
import ToastMessage from "../../toastMessage";
import useAuthStore from "../../../state/auth-state";
import { useNavigate } from "react-router-dom";

const AddUserComp = () => {
  const { user, setUser } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) return;

    const token = localStorage.getItem("admin-token");

    if (!user && !token) navigate("/");

    loginFromToken(token);
  }, [user]);

  const loginFromToken = async (token) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/token-sign-in`,
        {
          token,
        }
      );

      localStorage.removeItem("user-token");
      setUser(res.data);
    } catch (error) {}
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      password: "",
      email: "",
      phoneno: "",
      limit: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.name) {
        errors.name = "required";
      }
      if (!values.password) {
        errors.password = "required";
      }
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!values.phoneno) {
        errors.phoneno = "Required";
      } else if (!/^\d{11}$/i.test(values.phoneno)) {
        errors.phoneno = "Invalid phone number";
      }
      if (!values.limit) {
        errors.limit = "required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      addUser(values);
      resetForm();
    },
  });

  const addUser = async ({ name, password, email, phoneno, limit }) => {
    try {
      const newUser = {
        username: name,
        email,
        password,
        phoneNumber: phoneno,
        limit: Number(limit),
      };

      console.log(newUser);

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user`,
        newUser
      );

      ToastMessage("Success", "User added successfully", "success");
    } catch (error) {
      ToastMessage("Error", "Unknown error occured", "error");
    }
  };

  return user? (
    <MainSection>
      <div class="container">
        <div class="signup-container">
          <h2 class="signup-header">Add User</h2>
          <div class="form-wrapper">
            <form onSubmit={(e) => e.preventDefault()}>
              <div class="form-group">
                <label for="exampleInputName" class="mt-3">
                  Username:
                </label>
                <input
                  type="text"
                  class="form-control mt-2"
                  id="exampleInputName"
                  aria-describedby="emailHelp"
                  placeholder="Enter name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.name && errors.name && "required"}
                </span>
              </div>

              <div class="form-group">
                <label for="exampleInputPassword1" class="mt-3">
                  Password:
                </label>
                <input
                  type="password"
                  class="form-control mt-2"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.password && errors.password && "required"}
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="mt-3">
                  Email:
                </label>
                <input
                  type="email"
                  class="form-control mt-2"
                  id="exampleInputPassword1"
                  placeholder="Enter email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.email && errors.email && errors.email}
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="mt-3">
                  Phone no:
                </label>
                <input
                  class="form-control mt-2"
                  id="exampleInputPassword1"
                  placeholder="Enter phone number"
                  name="phoneno"
                  value={values.phoneno}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.phoneno && errors.phoneno && errors.phoneno}
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="mt-3">
                  Limit
                </label>
                <input
                  class="form-control mt-2"
                  id="exampleInputPassword1"
                  placeholder="Limit"
                  name="limit"
                  value={values.limit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div class="signup-btn-wrapper">
                <button
                  class="signup-btn-text"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Add User
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </MainSection>
  ) : <div></div>;
};

export default AddUserComp;
