import * as yup from "yup";

const validateSignUp = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  } else if (
    values.password &&
    values.confirmPassword &&
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = "password not match";
  }

  if (!values.name) {
    errors.name = "Required";
  }
  // if (!values.oldpassword) {
  //   errors.oldpassword = "Required";
  // }
  if (!values.walletAddress) {
    errors.walletAddress = "Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.walletAddress)
  ) {
    errors.walletAddress = "Invalid email address";
  }
  return errors;
};

export { validateSignUp };
