import SignupComp from "../components/admin-signup";

function Signup() {
  return (
    <div>
      <SignupComp />
    </div>
  );
}

export default Signup;
