import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./style.css";
import "./index.css";
import GlobalStyle from "./globalStyle";
import DashboardLayoutComp from "./components/dahboardLayout";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Home from "./pages/home";
import UserLogin from "./pages/user-login";
import UserSignup from "./pages/user-signup";
import UserForm from "./pages/user-form";

function App() {
  return (
    <div>
      <Helmet>
        <title>Admin Panel</title>
      </Helmet>
      <div className="app-wrapper" style={{ overflowX: "hidden" }}>
        <Router>
          <GlobalStyle />
          <div className="main">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard/*" element={<DashboardLayoutComp />} />
              <Route path="/zarar-t" element={<Login />} />
              {/* <Route path="/admin-sigin" element={<Signup />} /> */}
              <Route path="/user-login" element={<UserLogin />} />
              {/* <Route path="/user-sigin" element={<UserSignup />} /> */}
              <Route path="/user-form" element={<UserForm />} />
            </Routes>
          </div>
        </Router>
      </div>
    </div>
  );
}

export default App;
