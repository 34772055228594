import React from "react";
import {
  BodyDiv,
  HeaderDiv,
  MainBodyDiv,
  MainSection,
  MainWrapper,
} from "./elements";
import { RiDeleteBinLine } from "react-icons/ri";
const DeleteUser = () => {
  const data = [
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
    {
      name: "qadeer",
      email: "qaderr123@gmail.com",
      Password: "123455",
      phoneno: "0333xxxxxxx",
    },
  ];

  return (
    <MainSection>
      <MainWrapper>
        <HeaderDiv>
          <h5>User Name</h5>
          <h5>Email</h5>
          <h5>Password</h5>
          <h5>Phone no</h5>
          <h5>Delete</h5>
        </HeaderDiv>
        <MainBodyDiv className="scrollbar">
          {data.map((item) => (
            <BodyDiv>
              <h5>{item.name}</h5>
              <h5>{item.email}</h5> <h5>{item.Password}</h5>{" "}
              <h5>{item.phoneno}</h5>
              <h5>
                <RiDeleteBinLine color="red" fontSize={20} cursor="pointer" />
              </h5>
            </BodyDiv>
          ))}
        </MainBodyDiv>
      </MainWrapper>
    </MainSection>
  );
};

export default DeleteUser;
