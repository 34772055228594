import styled from "styled-components";

export const MainSection = styled.section``;

export const IuputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  input {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 0.5rem 1rem;
    width: 25rem;
    color: white;
    &::placeholder {
      font-size: 14px;
    }
    &:focus {
      outline: none;
    }
  }
`;
