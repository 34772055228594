import { useFormik } from "formik";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ToastMessage from "../toastMessage";
import useAuthStore from "../../state/auth-state";

function LoginComp() {
  const { loading, signIn } = "";

  const navigate = useNavigate();

  const { user, setUser } = useAuthStore();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!values.password) {
        errors.password = "required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      loginAdmin(values.email, values.password);
      resetForm();
    },
  });

  const loginAdmin = async (email, password) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/sign-in`,
        {
          email,
          password,
        }
      );

      setUser(res.data.admin);
      localStorage.setItem("admin-token", res.data.token);

      ToastMessage("Success", "Login successful", "success");

      localStorage.removeItem("user-token");
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 404) {
        ToastMessage("Error", "Invalid credentials", "error");
        console.error("Internal Server Error:", error.response.data);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  return (
    <div>
      <div class="container">
        <div class="signup-container">
          <h2 class="signup-header">Admin Login</h2>
          <div class="form-wrapper">
            <form onSubmit={(e) => e.preventDefault()}>
              <div class="form-group">
                <label for="exampleInputName" class="mt-3">
                  Email:
                </label>
                <input
                  type="text"
                  class="form-control mt-2"
                  id="exampleInputName"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.email && errors.email && errors.email}
                </span>
              </div>

              <div class="form-group">
                <label for="exampleInputPassword1" class="mt-3">
                  Password:
                </label>
                <input
                  type="password"
                  class="form-control mt-2"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.password && errors.password && "required"}
                </span>
              </div>

              {/* <div>
                <p style={{ color: "white", marginTop: "1rem" }}>
                  if you donot have account plz{" "}
                  <Link to="/admin-sigin">
                    <span
                      style={{
                        textDecoration: "underline",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                      create account
                    </span>
                  </Link>
                </p>
              </div> */}

              <div class="signup-btn-wrapper">
                <button
                  class="signup-btn-text"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  {loading ? "Loading" : "Login"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginComp;
