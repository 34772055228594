import React, { useEffect, useState } from "react";
import ToastMessage from "../../toastMessage";
import axios from "axios";
import useAuthStore from "../../../state/auth-state";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { validateSignUp } from "../../validations";

const ChangePassword = () => {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [repeated, setRepeated] = useState("");

  const { user, setUser } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) return;

    const token = localStorage.getItem("admin-token");

    if (!user && !token) navigate("/");

    loginFromToken(token);
  }, [user]);

  const loginFromToken = async (token) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/token-sign-in`,
        {
          token,
        }
      );

      localStorage.removeItem("user-token");
      setUser(res.data);
    } catch (error) {}
  };
  const handleChangePassword = async (e) => {
    e.preventDefault();

    try {
      if (oldPass === "" || newPass === "" || repeated === "") {
        ToastMessage("Error", "All fields are required", "error");
        return;
      }

      ToastMessage(
        "Request submitted",
        "Change password request submitted",
        "info"
      );

      const body = {
        adminId: user._id,
        oldPassword: oldPass,
        newPassword: newPass,
      };

      await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/change-password`,
        body
      );

      ToastMessage("Success", "Password updated successfully", "success");

      setOldPass("");
      setNewPass("");
      setRepeated("");
    } catch (error) {
      ToastMessage("Error", "Failed to update password", "error");
    }
  };

  const handleOldChange = (e) => {
    setOldPass(e.target.value);
  };

  const handleNewChange = (e) => {
    setNewPass(e.target.value);
  };
  const handleRepeatedChange = (e) => {
    setRepeated(e.target.value);
  };

  return user ? (
    <div>
      <div className="container">
        <div className="signup-container">
          <h2 className="signup-header">Change Pass</h2>
          <div className="form-wrapper">
            <form id="signup-form" onSubmit={handleChangePassword}>
              <div className="form-group">
                <label htmlFor="password">Enter Old Password:</label>
                <input
                  type="password"
                  className="form-control mt-2"
                  id="password"
                  placeholder="Enter old password"
                  name="oldpassword"
                  value={oldPass}
                  onChange={handleOldChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="mt-3">
                  Enter New Password
                </label>
                <input
                  type="password"
                  className="form-control mt-2"
                  id="password"
                  aria-describedby="textHelp"
                  placeholder="Enter New Password"
                  name="password"
                  value={newPass}
                  onChange={handleNewChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="mt-3">
                  Conform New Password
                </label>
                <input
                  type="password"
                  className="form-control mt-2"
                  id="password"
                  placeholder="Password"
                  name="confirmPassword"
                  value={repeated}
                  onChange={handleRepeatedChange}
                />
              </div>

              <div className="signup-btn-wrapper">
                <button
                  // onClick={handleChangePassword}
                  // onClick={() => {

                  //   handleChangePassword();
                  // }}
                  type="submit"
                  className="signup-btn-text"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default ChangePassword;
