import React from "react";
import UserFormComp from "../components/user-form";

const UserForm = () => {
  return (
    <div>
      <UserFormComp />
    </div>
  );
};

export default UserForm;
