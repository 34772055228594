import LoginComp from "../components/admin-login/login";

function Login() {
  return (
    <div>
      <LoginComp />
    </div>
  );
}

export default Login;
