import React from "react";
import { MainSection } from "./elements";
import { Link } from "react-router-dom";

const HomeComp = () => {
  return (
    <MainSection>
      {/* <Link to="/admin-login">
        <h5>Login As a admin</h5>
      </Link> */}
      <Link to="/user-login">
        <h5>Login As a user</h5>
      </Link>
    </MainSection>
  );
};

export default HomeComp;
