import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { MainSection } from "./elements";
import axios from "axios";
import ToastMessage from "../toastMessage";
import useAuthStore from "../../state/auth-state";
import { useEffect, useState } from "react";
import { Spin } from "antd";
function UserFormComp() {
  const { loading, signIn } = "";

  const [details, setDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [msisdn, setMsisdn] = useState("");
  const [limit, setLimit] = useState(0);

  const handleChange = (e) => {
    setMsisdn(e.target.value);
  };

  const { user, setUser } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setLimit(user.limit);
      return;
    }

    const token = localStorage.getItem("user-token");

    if (!user && !token) navigate("/");

    loginFromToken(token);
  }, [user]);

  const loginFromToken = async (token) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/token-sign-in`,
        {
          token,
        }
      );

      localStorage.removeItem("admin-token");
      setUser(res.data);
      setLimit(res.data.limit);
    } catch (error) {}
  };

  const getData = async () => {
    try {
      if (msisdn.length === "") {
        ToastMessage("Error", "Please enter a valid number", "error");
        return;
      }

      setDetails(undefined);
      setIsLoading(true);

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/msisdn`,
        {
          uid: user._id,
          number: msisdn,
        }
      );

      setDetails(res.data);
      setLimit(limit - 1);

      ToastMessage("Success", "Contact details fetched", "success");
    } catch (error) {
      ToastMessage(
        "Error",
        "Contact details not found or limit ended",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    setUser(undefined);
    navigate("/");
    localStorage.removeItem("user-token");
  };

  return (
    <div>
      <div class="container">
        <button
          onClick={handleLogout}
          style={{
            backgroundColor: "#333",
            borderRadius: 5,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 5,
            paddingBottom: 5,
            border: "none",
            marginTop: 30,
            color: "white",
          }}
        >
          Logout
        </button>
        <div class="signup-container">
          <div class="form-wrapper">
            <form onSubmit={(e) => e.preventDefault()}>
              <div class="form-group">
                <label for="exampleInputName" class="mt-3">
                  Enter MSISDN
                </label>
                <input
                  type="text"
                  class="form-control mt-2"
                  id="exampleInputName"
                  aria-describedby="emailHelp"
                  placeholder="Enter Number"
                  phone="phone"
                  value={msisdn}
                  onChange={handleChange}
                />
              </div>

              <div class="signup-btn-wrapper">
                <button
                  class="signup-btn-text"
                  onClick={getData}
                  disabled={loading}
                >
                  Send
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <p style={{ color: "white" }}>Total limits: {limit}</p>
              </div>
            </form>
            {details && (
              <MainSection>
                <div>
                  <h5>MSISDN</h5>
                  <p>{details.msisdn}</p>
                </div>
                <div>
                  <h5>Status</h5>
                  <p>{details.asset_status}</p>
                </div>
                <div>
                  <h5>Full name</h5>
                  <p>{details.customer_fullname}</p>
                </div>
                <div>
                  <h5>CNIC</h5>
                  <p>{details.CNIC}</p>
                </div>
              </MainSection>
            )}
            {isLoading && <Spin />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserFormComp;
