import styled from "styled-components";

export const DashboardLayoutWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
`;

export const ContentDiv = styled.div`
  position: relative;

  .navbar-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: #121318;
  }

  .flex-content-div {
    display: flex;
    justify-content: flex-start;
    gap: 32px;
  }
`;

export const RoutesWrapper = styled.div`
  flex-grow: 1;
  width: calc(100% + 244px);
  height: 100vh;
  overflow-y: auto !important;
  padding-top: 100px;
  padding-right: 2.5rem;

  @media (max-width: 992px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const SidebarWrapper = styled.div`
  width: 244px;
  transition: 0.3s all ease-in-out;
  padding-top: 100px;
  border-right: 2px solid white;
  background: black;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 992px) {
    display: none;
  }

  &.reducedWidth {
    width: 60px;
    transition: 0.3s all ease-in-out;
  }
`;

export const MainSection = styled.section``;

export const MenuContentDiv = styled.div`
  display: flex;

  flex-direction: column;
  gap: 1.125rem;
  border-bottom: 0.5px solid #83838f;
  padding-bottom: 0.8rem;

  .content-div {
    display: flex;
    align-items: center;
    gap: 1.125rem;
    cursor: pointer;
    align-items: center;
    height: 40px;
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }
  h5 {
    color: white;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 224.5%; /* 2.245rem */
    text-transform: capitalize;
    margin: 0;
  }
`;

export const FollowText = styled.h5`
  color: #83838f;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 224.5%; /* 2.245rem */
  text-transform: capitalize;
  margin-top: 1.5rem;
`;

export const IconDiv = styled.div``;

export const LogoutBtn = styled.button`
  margin-top: 2rem;
  background-color: white;
  color: black;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
`;
