import React from "react";
import UserLoginComp from "../components/user-login";

const UserLogin = () => {
  return (
    <div>
      <UserLoginComp />
    </div>
  );
};

export default UserLogin;
