import { useFormik } from "formik";
import { validateSignUp } from "../validations";

function UserSignupComp() {
  const { loading, signUp } = "";

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        name: "",
        password: "",
        confirmPassword: "",
        walletAddress: "",
      },
      validate: validateSignUp,
      onSubmit: async (values) => {
        console.log('has')
        signUp(values);
      },
    });
  return (
    <div>
      <div className="container">
        <div className="signup-container">
          <h2 className="signup-header">User Signup</h2>
          <div className="form-wrapper">
            <form id="signup-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Enter Name:</label>
                <input
                  type="text"
                  className="form-control mt-2"
                  id="name"
                  placeholder="Enter Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.name && errors.name && "required"}
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="walletAddress" className="mt-3">
                  email
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  id="walletAddress"
                  aria-describedby="textHelp"
                  placeholder="Wallet email"
                  name="walletAddress"
                  value={values.walletAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.walletAddress && errors.walletAddress}
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="password" className="mt-3">
                  Password:
                </label>
                <input
                  type="password"
                  className="form-control mt-2"
                  id="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.password && errors.password}
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword" className="mt-3">
                  Confirm Password:
                </label>
                <input
                  type="password"
                  className="form-control mt-2"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.confirmPassword && errors.confirmPassword}
                </span>
              </div>
              <div className="signup-btn-wrapper">
                <button type="submit" className="signup-btn-text">
                  Signup
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSignupComp;
