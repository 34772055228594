import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import ToastMessage from "../toastMessage";
import axios from "axios";
import useAuthStore from "../../state/auth-state";
function UserLoginComp() {
  const { loading, signIn } = "";

  const navigate = useNavigate();

  const { setUser } = useAuthStore();

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validate: (values) => {
        let errors = {};
        if (!values.email) {
          errors.email = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        if (!values.password) {
          errors.password = "required";
        }
        return errors;
      },
      onSubmit: async (values) => {
        loginUser(values.email, values.password);
      },
    });

  const loginUser = async (email, password) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/sign-in`,
        {
          email,
          password,
        }
      );

      setUser(res.data.user);
      localStorage.setItem("user-token", res.data.token);

      ToastMessage("Success", "Login successful", "success");

      localStorage.removeItem("admin-token");
      navigate("/user-form");
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 404) {
        ToastMessage("Error", "Invalid credentials", "error");
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  return (
    <div>
      <div class="container">
        <div class="signup-container">
          <h2 class="signup-header">User Login</h2>
          <div class="form-wrapper">
            <form onSubmit={(e) => e.preventDefault()}>
              <div class="form-group">
                <label for="exampleInputName" class="mt-3">
                  Email:
                </label>
                <input
                  type="text"
                  class="form-control mt-2"
                  id="exampleInputName"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.email && errors.email && errors.email}
                </span>
              </div>

              <div class="form-group">
                <label for="exampleInputPassword1" class="mt-3">
                  Password:
                </label>
                <input
                  type="password"
                  class="form-control mt-2"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span style={{ color: "#F65D5D", marginTop: "1rem" }}>
                  {touched.password && errors.password && "required"}
                </span>
              </div>

              <div class="signup-btn-wrapper">
                <button
                  class="signup-btn-text"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  {loading ? "Loading" : "Login"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLoginComp;
