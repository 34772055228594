import React, { useState } from "react";
import {
  IconDiv,
  LogoutBtn,
  MainSection,
  MenuContentDiv,
  SidebarWrapper,
} from "./element";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoSearchSharp } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import useAuthStore from "../../state/auth-state";
const menudata = [
  {
    title: "Home",
    icon: <IoHomeOutline color="white" fontSize={20} />,
    link: "/dashboard",
  },
  {
    title: "Add User",
    icon: <FaRegUser color="white" fontSize={20} />,
    link: "/dashboard/add-user",
  },
  // {
  //   title: "Delete User",
  //   icon: <FaRegUser color="white" fontSize={20} />,
  //   link: "/dashboard/delete-user",
  // },

  {
    title: "Total User",
    icon: <FaRegUser color="white" fontSize={20} />,
    link: "/dashboard/total-user",
  },
  {
    title: "Total User Log",
    icon: <FaRegUser color="white" fontSize={20} />,
    link: "/dashboard/total-user-log",
  },
  // {
  //   title: "Log Searching",
  //   icon: <IoSearchSharp color="white" fontSize={20} />,
  //   link: "/dashboard/log-search",
  // },
  {
    title: "Change Pass",
    icon: <RiLockPasswordFill color="white" fontSize={20} />,
    link: "/dashboard/change-pass",
  },
];

function SidebarComp() {
  const [hovered, setHovered] = useState(true);

  const { setUser } = useAuthStore();

  const handleLogout = () => {
    setUser(undefined);
    localStorage.removeItem("admin-token");
  };

  return (
    <SidebarWrapper
      className={hovered ? "" : "reducedWidth"}
      // onMouseEnter={() => setHovered(true)}
      // onMouseLeave={() => setHovered(false)}
    >
      <MainSection>
        {menudata.map((item) => (
          <MenuContentDiv>
            <Link to={item.link}>
              <div className="content-div">
                <IconDiv>{item.icon}</IconDiv>
                <AnimatePresence>
                  {hovered && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.25, delay: 0.25 }}
                    >
                      <h5>{item.title}</h5>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </Link>
          </MenuContentDiv>
        ))}

        <LogoutBtn onClick={handleLogout}>logout</LogoutBtn>
      </MainSection>
    </SidebarWrapper>
  );
}

export default SidebarComp;
