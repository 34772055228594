import styled from "styled-components";

export const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  padding-bottom: 3rem;
`;

export const MainWrapper = styled.div`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* padding: 1rem 2rem 0.5rem 2rem; */
  border-radius: 10px;
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 1rem 2rem 0.5rem 2rem;
  h5 {
    color: white;
    flex: 1;
    text-align: center;
  }
`;

export const BodyDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0.5rem 2rem 0.5rem 2rem;
  h5 {
    color: white;
    font-size: 1rem;
    flex: 1;
    text-align: center;
  }
`;

export const MainBodyDiv = styled.div`
  height: 30rem;
  overflow-y: scroll;
`;
