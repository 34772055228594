import React from "react";
import { DashboardLayoutWrapper, ContentDiv, RoutesWrapper } from "./element";
import NavbarComp from "../navbar";
import SidebarComp from "./sidebar";
import { Routes, Route } from "react-router-dom";
import {
  AddUser,
  ChangePassword,
  DeleteUser,
  Home,
  LogSearch,
  TotalUser,
  TotalUserLog,
} from "../dashboardScreens";

function DashboardLayoutComp() {
  return (
    <DashboardLayoutWrapper>
      <ContentDiv>
        <div className="navbar-div">
          <NavbarComp />
        </div>

        <div className="flex-content-div">
          <SidebarComp />
          <RoutesWrapper>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/add-user" element={<AddUser />} />
              <Route path="/delete-user" element={<DeleteUser />} />
              <Route path="/total-user" element={<TotalUser />} />
              <Route path="/total-user-log" element={<TotalUserLog />} />
              <Route path="/log-search" element={<LogSearch />} />
              <Route path="/change-pass" element={<ChangePassword />} />
            </Routes>
          </RoutesWrapper>
        </div>
      </ContentDiv>
    </DashboardLayoutWrapper>
  );
}

export default DashboardLayoutComp;
