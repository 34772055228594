import styled, { createGlobalStyle } from "styled-components";
import fontlight from "./assets/fonts/Black-Bison.ttf";
const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'Black bison';
    src: local('Black bison'), url(${fontlight}) format("truetype") ;
    font-display:swap;
    font-style: normal;
}





body 
{
  background: #121318 !important;
  font-family: 'Hind', sans-serif !important;
 
}

a{
  text-decoration:none !important;
}

h1 
{
  font-family: 'Black bison' !important;
}

.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  color: white;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-footer
  {
    display: none;
  }
  .ant-modal-close
  {
    display: none;
  }
  .ant-modal-content
  {
   background: none;
  }
  .ant-modal-body
  {
    padding: 0px;
  }
  .ant-modal-mask
  {
    background-color: rgb(0 0 0 / 78%) ;
  }

  .container-fluid {
    padding: 6rem 162px 4rem 162px !important;

    @media (max-width: 767.98px) {
      padding-right: 2rem !important;
      padding-left: 2rem !important;
    }
    @media (max-width: 575.98px) {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }

  .nav-container {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.arrow-disabled {
  opacity: 0.25;
  cursor: not-allowed !important;
}

.scrollbar::-webkit-scrollbar {
  width: 0.3rem;
  border-radius: 3.75rem;
}

.scrollbar::-webkit-scrollbar-track {
  background: #e1e1e1;
  opacity: 0.51;
  border-radius: 3.75rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 3.75rem;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: gray;
}


`;

export default GlobalStyle;
